import { Link } from '@mentimeter/ragnar-ui';
import * as React from 'react';
import { useSplitTrack, useSplitTreatments } from '../../../split.io';
import { FooterContainer } from './FooterContainer';

const wwwUrl = globalThis.__mentimeterEnv['NEXT_PUBLIC_WWW_URL'];

const DEFAULT_UTM_URL_PARAMS =
  'utm_campaign=powered%20by%20mentimeter%20button&utm_medium=web-link&utm_source=govote';

interface WebFooterProps {
  utmUrlParams?: string | undefined;
  onMentimeterLinkClick?: (() => void) | undefined;
  context?: 'Home' | undefined;
}

export const DefaultFooter = ({
  utmUrlParams = DEFAULT_UTM_URL_PARAMS,
  onMentimeterLinkClick,
  context,
}: WebFooterProps) => {
  const trackSplit = useSplitTrack();

  const handleMentimeterLinkClick = () => {
    trackSplit('Clicked_through_to_mentimeter');
    onMentimeterLinkClick?.();
  };
  const { OG_Menti_Finish_Button_v2 } = useSplitTreatments([
    'OG_Menti_Finish_Button_v2',
  ]);

  return (
    <>
      <FooterContainer.Text>
        Create your own Menti at&nbsp;
        <Link
          href={`${wwwUrl}/auth/justvoted?${utmUrlParams}&utm_content=${OG_Menti_Finish_Button_v2?.treatment}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleMentimeterLinkClick}
        >
          mentimeter.com
        </Link>
      </FooterContainer.Text>
      {context === 'Home' && (
        <FooterContainer.TermsText>
          By using Mentimeter you accept our{' '}
          <FooterContainer.TermsOfUseLink>
            terms of use
          </FooterContainer.TermsOfUseLink>{' '}
          and{' '}
          <FooterContainer.PoliciesLink>policies</FooterContainer.PoliciesLink>.
        </FooterContainer.TermsText>
      )}
    </>
  );
};
